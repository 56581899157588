import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  Popover,
  Paper,
  Typography,
  Tabs,
  Tab,
  Slider,
  AppBar,
  styled,
  Button,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { colors } from "../../../blocks/utilities/src/Colors";

export const InputField: any = withStyles({
  root: {
    width: "100%",
    paddingTop: "10px",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-root": {
      fontWeight: 500,
      fontSize: "14px",
      border: "1px solid #ECECEC",
      fontFamily: "Montserrat",
      borderRadius: "8px",
      "& fieldset": {
        fontSize: "14px",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
        fontFamily: "Montserrat",
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#4D4D4D",
      fontSize: "14px",
      fontFamily: "Montserrat",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "12px"
      },
    },
  },
})(TextField);

export const StyledTextField = withStyles({
  root: {
    width: "100%",
    paddingTop: "10px",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontSize: "14px",
      border: "1px solid #ECECEC",
      fontFamily: "Montserrat",
      borderRadius: "8px",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "13px",
      },
      "& fieldset": {
        fontSize: "14px",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
        fontFamily: "Montserrat",
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#4D4D4D",
      fontSize: "14px",
      fontFamily: "Montserrat",
      "@media only screen and (max-width: 1024px)": {
          fontSize: '12px',
      },
    },
    "@media only screen and (max-width: 1024px)": {
      '& .MuiInputBase-input': {
        fontSize: '12px',
        padding:'14.9px 14px'
      },
    },
  },
})(TextField);

export const CustomFormControl: any = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },
})(FormControl);

export const MultiSelectionBox: any = withStyles({
  '@global': {
    '*::-webkit-scrollbar': {
        width: '6px'
    },
    '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#d1d1d1',
        borderRadius: '10px'
    },
    "@media only screen and (max-width: 1024px)": {
    '.MuiTypography-body1': {
      fontSize: '14px',
    }
  }
  },
  root: {
    scrollbarWidth: "thin",
    scrollbarColor: "#d1d1d1 transparent",
    width: "100%",
    borderRadius: "8px",
    margin: 0,
    padding: 0,
    paddingLeft: "16px",
    height: "56px",
    fontSize: "14px",
    color: "#4d4d4d",
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    maxHeight: "70px",
    overflow: "auto",
    "@media only screen and (max-width: 1024px)": {
      maxHeight:"44px",
      minHeight: "44px",
    },
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#d1d1d1",
        borderRadius: "10px",
      },
    },
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      background: "#d9d9d9",
      borderRadius: "10px",
      height: "5px",
    },

    "&:focus": {
      outline: "none",
      borderRadius: "8px",
    },

    "& > :first-child": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      fontFamily: "Montserrat",
      height: "56px",
    },
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontSize: "16px",
      borderRadius: "8px",
      "& fieldset": {
        borderWidth: 1,
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: "8px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ececec",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ececec",
      },
    },
    "& .MuiInputBase-root": {
      height: 56,
    },
    "& .MuiSelect-root": {
      paddingLeft: "16px",
      color: "#4d4d4d",
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px transparent",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#d9d9d9",
        borderRadius: "10px",
        height: "5px",
      },
    },
    "& ::placeholder": {
      opacity: 1,
      fontSize: "14px",
      color: "#4D4D4D",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "10px",
      fill: "black",
    },
    "& .MuiFormControlLabel-root": {
      height: "56px",
      display: "none",
      margin: "0 10px",
      "& .MuiButtonBase-root": {
        display: "none",
      },
    },
  },
})(Select);

export const CustomMenuItem: any = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "& .MuiSvgIcon-root": {
      fill: "black",
      marginRight: "10px",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: "6px",
    },
    "&.Mui-selected": {
      color: "#204B9C",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
    "& .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .MuiSvgIcon-root": {
        fill: "black",
        marginRight: "10px",
      },
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  },
})(MenuItem);

export const CustomPopover: any = withStyles({
  root: {
    "& .MuiPaper-elevation8": {
      width: "118px",
      fontFamily: "Montserrat",
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    },
  },
})(Popover);

export const CustomPaper: any = withStyles({
  root: {
    background: "#fff",
    borderRadius: "12px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 5px 32px rgba(0, 0, 0, 0.06)",
    padding: "32px",
  },
})(Paper);

export const SimpleCustomSelect: any = withStyles({
  root: {
    width: "100%",
    borderRadius: "8px",
    padding: 0,
    margin: 0,
    height: "56px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
    color: "#4d4d4d",
    fontSize: "14px",
    fontFamily: "Montserrat",
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#d1d1d1",
        borderRadius: "10px",
      },
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      fontWeight: 500
    },
  },
})(Select);

export const SimpleCustomMenuItem: any = withStyles({
  root: {
    fontFamily: "Montserrat",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "10px",
      fill: "black",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: "6px",
    },
    "&.Mui-selected": {
      color: "#ffffff",
      backgroundColor: "#204B9C",
      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#204B9C",
      },
    },
    "& .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .MuiSvgIcon-root": {
        marginRight: "10px",
        fill: "black",
      },
    },
    "& .MuiListItem-button": {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  },
})(MenuItem);

export const SimpleCustomAutocomplete: any = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      "@media only screen and (max-width: 1024px)": {
        '& .MuiInputBase-input': {
          fontSize: '12px',
        },
      },
    },
  },
})(Autocomplete);

export const CustomTypography: any = withStyles({
  root: {
    fontSize: "20px",
  },
})(Typography);

export const CustomTabs: any = withStyles({
  root: {
    padding: "2px",
    backgroundColor: "#f8fafc",
    borderRadius: "40px",
    margin: "20px auto",
    display: "flex",
    justifyContent: "center",
    textTransform: "none",
    minHeight: "40px",
  },
})(Tabs);

export const CustomTab: any = withStyles({
  root: {
    minWidth: 0,
    minHeight: "40px",
    borderRadius: "40px",
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: "#f8fafc",
    color: "#4D4D4D",
    maxWidth: "350px",
    "&.MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#204B9C",
        background: "#ffffff",
        boxShadow:
          "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
      },
    },
  },
})(Tab);

export const CustomSortingPopover: any = withStyles({
  root: {
    fontFamily: "Montserrat",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    "& .MuiPaper-elevation8": {
      width: "480px",
      fontFamily: "Montserrat",
      boxShadow:
        "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    },
  },
})(Popover);

export const FilterTypography: any = withStyles({
  root: {
    background: "#fff",
    color: "#000000",
    height: "33px",
    borderRadius: "0",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    margin: "5px 0",
    boxShadow: "none",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
    fontFamily: "Montserrat",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#fff",
    },
  },
})(Paper);

export const FilterCustomAutocomplete: any = withStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      paddingRight: "10px !important",
    },
    inputRoot: {
      "& .MuiAutocomplete-endAdornment": {
        display: "none",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
  },
})(Autocomplete);

export const CustomSlider: any = withStyles({
  root: {
    "&.MuiSlider-root": {
      width: "88%",
      margin: "0 15px 0 20px",
    },
  },
})(Slider);

export const StyledAppBar: any = withStyles({
  root: {
    backgroundColor: "#FFF",
    color: "#4D4D4D",
    marginTop: "10px",
    borderBottom: "1px solid #ECECEC",
    boxShadow: "none",
    "& .Mui-selected": {
      color: "#204B9C",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#204B9C !important",
    },
    "& .MuiPaper-elevation": {
      boxShadow: "none",
    },
  },
})(AppBar);

export const HeaderText = styled(Typography)({
  fontFamily: "Montserrat !important",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    lineHeight: '19.2px'
  },
})

export const InputLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  alignItems: "center",
  letterSpacing: "-0.3px",
  marginBottom:'6px',
  color: colors().charcoal,
  fontFamily: "Montserrat !important",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    lineHeight: "15.6px",
  },
})

export const SelectFieldCustom = styled(Select)({
  width: "100%",
  height: "56px",
  marginTop: "4px",
  fontFamily: "Montserrat !important",
  "@media only screen and (max-width: 1024px)": {
    height: '44px',
    '& .MuiInputBase-input': {
      fontSize: '13px',
    },
  },
})

export const SelectMenuItem = styled(MenuItem)({
  "@media only screen and (max-width: 1024px)": {
    fontSize: '14px',
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },

    height: '44px'
  },
})

export const CustomStyledTextField = styled(TextField)({
  width: "100%",
  height: "56px",
  marginTop: "4px",
  fontFamily: "Montserrat !important",
  "@media only screen and (max-width: 1024px)": {
    height:'44px',
    '& .MuiInputBase-input': {
      fontSize: '13px',
      padding: '14.9px 14px'
    },
  },
});
export const DefultBox = styled(Box)({
  fontFamily: "Montserrat !important",
  fontSize: '16px',
  "@media only screen and (max-width: 1024px)": {
    fontSize: '13px',
  },
});


export const SaveAndCancelButton = styled(Button)({
  height: "56px",
  "@media only screen and (max-width: 1024px)": {
    height: "44px",
  },
})

export const FilterBox = styled('div')({
  position: "relative",
  cursor: "pointer",
  height: "56px",
  background: colors().white,
  color: colors().darkliver,
  borderColor: colors().lightborder,
  width: "56px",
  fontSize: "24px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    width: "44px",
    height: "44px",
  },
  "&.active-border" : {
    borderColor: colors().cyancobaltblue,
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%"
  },
});

export const MainButtonComponent = styled(Button)({
  background: "#204B9C",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: colors().white,
  height: "50px",
  width: "186px",
  borderRadius: "8px",
  marginRight: 32,
  textTransform: "unset" as const,
  "&.MuiButton-root.Mui-disabled": {
    color: colors().white,
    cursor: "not-allowed",
    opacity: 0.5,
  },
  "&:hover": {
    background: "#204B9C",
    color: colors().white,
  },
  "&.buttonSecondary": {
    backgroundColor: "#F1F5F9",
    color: "#64748B",
  },
  "&.fullWidth": {
    width: "100%",
  },
  "&.mt-24": {
    marginTop: 24,
  },
});

export const HeaderStyledButton = styled(Button)({
  background: "#204B9C",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FFFFFF",
  height: "56px",
  width: "184px",
  borderRadius: "8px",
  cursor: "pointer",
  textTransform: "unset",
  "&.ml-12": {
    margin: "0 0 0 12px",
  },
  "&:hover": {
    background: "#204B9C !important",
    color: "#FFFFFF !important",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(32, 75, 156, 0.5) !important",
    color: "#FFFFFF",
    opacity: 0.7,
  },
  "@media (max-width: 1024px)": {
    fontSize: "13px",
    width: "160px",
    height: "44px",
  },
});

export const ListingPageHeading = styled(Typography)({
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "32px",
  color: colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

export const StyledExportButton = styled(Button)({
  fontSize: "16px",
  fontWeight: 600,
  maxWidth: "184px",
  width: "184px",
  height: "56px",
  borderRadius: "8px",
  lineHeight: "24px",
  margin: "5px",
  textTransform: "unset",
  background: "#204B9C",
  color: "#FFFFFF",
  "&:hover": {
    background: "#204B9C !important",
    color: "#FFFFFF !important",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(32, 75, 156, 0.5) !important",
    color: "#FFFFFF",
    opacity: 0.7,
  },
  "@media (max-width: 1024px)": {
    width: "150px",
    height: "44px",
    fontSize: "13px",
    padding: "10px 0",
    margin: "2px",
  },
});