// Customizable Area Start
import React from "react";
import B2bBussinessAccountListController, {
  Props,
  configJSON,
} from "./Cfb2bbussingessAccountListController";
import {
  styled,
  Box,
  CircularProgress,
  Typography,
  Button,
  IconButton,
  Popover,
  TablePagination,
} from "@material-ui/core";
import {
  ListingPageHeading,
  FilterBox,
  MainButtonComponent,
} from "../../../components/src/customComponents/CustomTags.web";
import {
  renderBaseOnConditions,
  sortStringCondition,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import { LoadingComponent } from "../../../components/src/Loader.web";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.strings.b2bBussinessListString;

const staticObj = {
  customerName: "Pritesh Makasana",
  email: "pritesh313@gmail.com",
  phoneNo: "+966-8748374742",
  company: "Saudi Aramco",
  priceList: "Al Jabr Master PriceList",
  startDate: "21-10-2024",
  endDate: "21-10-2024",
  status: true,
};
const tableStaticData = new Array(10).fill(staticObj);
// Customizable Area End

export default class B2bBussinessAccountList extends B2bBussinessAccountListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleReturnStatusColumn = () => {
    const open = Boolean(this.state.openActionPopover);
    const popoverId = open ? "simple-popover" : undefined;

    return (
      <Box className={"statusLastColumn"}>
        <CustomSwitch
          checked={false}
          onChange={(checked) => console.debug(checked)}
        />
        <Box className={"bottomStatusCell"}>
          <Button className={"viewStatusButton"}>
            {Strings.tabelString_viewStatus}
          </Button>
          <IconButton
            className={"moreMenuButton"}
            data-test-id={"more-menu-button"}
            aria-describedby={popoverId}
            onClick={(event) => this.handleOpenActionPopver(event)}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  getImportExportPopover = () => {
    const { openImportExportPopover } = this.state;
    const open = Boolean(openImportExportPopover);
    const popoverId = open ? "simple-popover" : undefined;
    return (
      <ExportImportPopover
        popOverId={popoverId}
        openAction={open}
        popoverOpen={openImportExportPopover}
        popoverClose={() => this.handleCloseImportExportPopver()}
        exportTemplateButtonClick={() => console.debug("template")}
        importCsvButtonClick={() => console.debug("import")}
        exportCsvButtonClick={() => console.debug("export")}
      />
    );
  };

  getActionPopover = () => {
    const { openActionPopover } = this.state;
    const open = Boolean(this.state.openActionPopover);
    const popOverId = open ? "simple-popover" : undefined;

    return (
      <ActionPopover
        data-test-id='popver'
        style={{ boxShadow: "none" }}
        id={popOverId}
        open={open}
        anchorEl={openActionPopover}
        onClose={this.handleCloseActionPopver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {renderBaseOnConditions(
          true,
          <div className={"popoverContainer"}>
            {renderBaseOnConditions(
              true,
              <Box className={"popoverButton"}>{Strings.view}</Box>,
              <></>
            )}
            {renderBaseOnConditions(
              true,
              <Box className={"popoverButton"}>{Strings.delete}</Box>,
              <></>
            )}
            {renderBaseOnConditions(
              true,
              <Box className={"popoverButton"}>{Strings.renew}</Box>,
              <></>
            )}
          </div>,
          <div className={"popoverContainer"}>
            <Box className={"noPermissions"}>{Strings.noPermissions}</Box>
          </div>
        )}
      </ActionPopover>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openImportExportPopover, openActionPopover } = this.state;
    const open = Boolean(openImportExportPopover);
    const popoverId = open ? "simple-popover" : undefined;
    // Customizable Area End
    return (
      // Customizable Area Start
      <BusinessAcountListStyleWrapper>
        <Box className={"topHeaderBox"}>
          <ListingPageHeading>{Strings.headingTitle}</ListingPageHeading>
          <Box className={"topRightBox"}>
            <FilterBox
              data-test-id={"filterBtn"}
              className={sortStringCondition(
                this.state.isAppliedFilter,
                "active-border",
                ""
              )}
            >
              <FilterIcon color={this.handleReturnColorType()} />
              {renderBaseOnConditions(
                this.state.isAppliedFilter,
                <div className={"filterActiveIcon"} />,
                <></>
              )}
            </FilterBox>
            <Box>
              <LoadingComponent
                isLoading={this.state.isLoadingPermission}
                height={56}
                width={175}
                isAllowed={true} // showing on import/export permissions
                children={
                  <IconButton
                    data-test-id={`export-import-btn`}
                    id={"IconButton"}
                    aria-describedby={popoverId}
                    onClick={(event) => this.openImportExportPopover(event)}
                    className={"export-btn"}
                  >
                    {Strings.exportimportText}{" "}
                    {openImportExportPopover ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                }
              />
            </Box>
            {renderBaseOnConditions(
              true,
              <Box>
                <LoadingComponent
                  isLoading={this.state.isLoadingPermission}
                  height={56}
                  width={175}
                  isAllowed={true} // showing on import/export permissions
                  children={
                    <MainButtonComponent className={"mr-0"}>
                      {Strings.addAccount}
                    </MainButtonComponent>
                  }
                />
              </Box>,
              <></>
            )}
          </Box>
        </Box>
        <Box>
          <OrderTable>
            <RowContainer header>
              <TableRow columns={"1fr 1fr 1fr 1fr 1fr 1fr 0.9fr 1.1fr"}>
                {[
                  {
                    title: Strings.tabelString_customerName,
                    sortingKey: "customer",
                  },
                  {
                    title: Strings.tabelString_email,
                    sortingKey: "customer",
                  },
                  {
                    title: Strings.tabelString_phoneNo,
                    sortingKey: "customer",
                  },
                  {
                    title: Strings.tabelString_company,
                    sortingKey: "customer",
                  },
                  {
                    title: Strings.tabelString_priceList,
                    sortingKey: "customer",
                  },
                  {
                    title: Strings.tabelString_startDate,
                    sortingKey: "customer",
                  },
                  {
                    title: Strings.tabelString_endDate,
                    sortingKey: "customer",
                  },
                  {
                    title: Strings.tabelString_status,
                    sortingKey: "customer",
                  },
                ]
                  .filter((item: { title: string }) => {
                    return item.title;
                  })
                  .map(
                    ({
                      title,
                      sortingKey,
                    }: {
                      title: string;
                      sortingKey?: string;
                    }) => (
                      <TableCell
                        header
                        key={title}
                        content={title}
                        className={"f-16 align-item-center"}
                        sortingKey={sortingKey}
                        sortingIcons={[
                          IconSortingNormal,
                          IconSortingUp,
                          IconSortingDown,
                        ]}
                        sortingDisabled={!Boolean(sortingKey)}
                        {...this.getSortingProps()}
                      />
                    )
                  )}
              </TableRow>
            </RowContainer>
            {renderBaseOnConditions(
              false, // loading state
              <RowContainer>
                <Box className={"tableBodyLoader"}>
                  <CircularProgress />
                </Box>
              </RowContainer>,
              <RowContainer>
                {renderBaseOnConditions(
                  true, // add data lenth conditions
                  <>
                    {tableStaticData.map((data) => {
                      return (
                        <TableRow
                          key={data}
                          className={"table-rows"}
                          columns={"1fr 1fr 1fr 1fr 1fr 1fr 0.9fr 1.1fr"}
                        >
                          {[
                            data.customerName,
                            data.email,
                            data.phoneNo,
                            data.company,
                            data.priceList,
                            data.startDate,
                            data.endDate,
                            this.handleReturnStatusColumn(),
                          ].map((item, index) => {
                            return (
                              <TableCell
                                key={`cell-${index}`}
                                style={{ wordBreak: "break-all" }}
                                content={item as React.ReactNode}
                                className={"f-12"}
                                type={this.getTypeOfOrderTable(index)}
                              />
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </>,
                  <Typography className={"noDataFound"}>
                    {Strings.tabelString_noAccountDataFound}
                  </Typography>
                )}
              </RowContainer>
            )}
          </OrderTable>
        </Box>
        {renderBaseOnConditions(
          true,
          <TablePagination
            onPageChange={(_event, page) => console.debug(page)}
            count={this.state.meta.total_count}
            component={"div"}
            data-test-id={"pagination"}
            page={this.state.page}
            rowsPerPage={this.state.rowPerPage}
            rowsPerPageOptions={[10]}
            className={"tablePaginationBox"}
          />,
          <></>
        )}
        {openActionPopover && this.getActionPopover()}
        {openImportExportPopover && this.getImportExportPopover()}
      </BusinessAcountListStyleWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BusinessAcountListStyleWrapper = styled(Box)({
  width: "100%",
  "& .topHeaderBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "17px 0 32px 0",
  },
  "& .topRightBox": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  "& .tableBodyLoader": {
    textAlign: "center",
    paddingBottom: 15,
  },
  "& .noDataFound": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "8px 0 12px 0",
  },
  "& .viewStatusButton": {
    padding: "4px 10px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 600,
    color: colors().cyancobaltblue,
    background: "rgba(32, 75, 156, 0.1)",
    fontFamily: "Montserrat",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  "& .moreMenuButton": {
    padding: "5px",
    borderRadius: "8px",
    textAlign: "center",
    color: colors().viewTextColor,
    background: "rgba(32, 75, 156, 0.1)",
    cursor: "pointer",
  },
  "& .bottomStatusCell": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .statusLastColumn": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "12px",
  },
  "& .tablePaginationBox": {
    width: "100%",
    padding: "8px 0",
  },
  "& .export-btn": {
    width: "auto !important",
    margin: "0 10px",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px !important",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important",
      width: "160px !important",
      height: "44px !important",
    },
  },
});

const ActionPopover = styled(Popover)({
  "& .noPermissions": {
    padding: "0 8px",
  },
  "& .popoverContainer": {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  "& .popoverButton": {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    paddingTop: "8px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
});
// Customizable Area End
