// App.js - WEB
import React, { Component, Suspense, lazy } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { lazyRetry } from '../../components/src/common';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
const TopNav = lazy(() => lazyRetry(() => import('../../components/src/TopNav')));

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';

import TaskList from '../../blocks/TaskList/src/TaskList';
import StoreLocator from '../../blocks/StoreLocator/src/StoreLocator';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import Archive from '../../blocks/Archive/src/Archive';
import CreateComment from '../../blocks/comments/src/CreateComment';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import ElasticSearch from '../../blocks/ElasticSearch/src/ElasticSearch';
import PerformanceTracker from '../../blocks/PerformanceTracker/src/PerformanceTracker';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import ApplePayIntegration from '../../blocks/ApplePayIntegration/src/ApplePayIntegration';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import LoyaltySystem2 from '../../blocks/LoyaltySystem2/src/LoyaltySystem2';
import Surveys from '../../blocks/Surveys/src/Surveys';
import Location from '../../blocks/location/src/Location';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import CfRegionsManagement from '../../blocks/CfRegionsManagement/src/CfRegionsManagement';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Sorting from '../../blocks/sorting/src/Sorting';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import BarcodeSettings from '../../blocks/BarcodeSettings/src/BarcodeSettings';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import ItemGrouper from '../../blocks/ItemGrouper/src/ItemGrouper';
import Blockedusers from '../../blocks/blockedusers/src/Blockedusers';
import AddBlockeduser from '../../blocks/blockedusers/src/AddBlockeduser';
import ReceiptCustomisation from '../../blocks/ReceiptCustomisation/src/ReceiptCustomisation';
import AmazonPayIntegration from '../../blocks/AmazonPayIntegration/src/AmazonPayIntegration';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import Analytics from '../../blocks/analytics/src/Analytics';
import ServiceSpecificSettingsAdmin from '../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin';
import Crm3rdPartyIntegration from '../../blocks/Crm3rdPartyIntegration/src/Crm3rdPartyIntegration';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import TaskViews from '../../blocks/TaskViews/src/TaskViews';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import BarcodesScanner from '../../blocks/BarcodesScanner/src/BarcodesScanner';
import MultipleCurrencySupport from '../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport';
import OrganisationHierarchy from '../../blocks/OrganisationHierarchy/src/OrganisationHierarchy';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import HelpdeskReporting from '../../blocks/HelpdeskReporting/src/HelpdeskReporting';
import TaxCalculator from '../../blocks/TaxCalculator/src/TaxCalculator';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import ExpressDelivery from '../../blocks/ExpressDelivery/src/ExpressDelivery';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import CfWhatsappApi from '../../blocks/CfWhatsappApi/src/CfWhatsappApi';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import Payments from '../../blocks/Payments/src/Payments';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import AgentDashboard2 from '../../blocks/AgentDashboard2/src/AgentDashboard2';
import QrCodes from '../../blocks/qrcodes/src/QrCodes';
import AccountTransfer from '../../blocks/AccountTransfer/src/AccountTransfer';
import Notes from '../../blocks/Notes/src/Notes';
import Couponcodegenerator from '../../blocks/couponcodegenerator/src/Couponcodegenerator';
import HelpCentre from '../../blocks/helpcentre/src/HelpCentre';
import HelpCentreQA from '../../blocks/helpcentre/src/HelpCentreQA';
import HelpCentreSub from '../../blocks/helpcentre/src/HelpCentreSub';
import Geofence from '../../blocks/geofence/src/Geofence';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import Customform from '../../blocks/customform/src/Customform';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import CfSubCompany from '../../blocks/CfSubCompany/src/CfSubCompany';
import TicketSystem from '../../blocks/TicketSystem/src/TicketSystem';
import OfflineWork from '../../blocks/OfflineWork/src/OfflineWork';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Notifications from '../../blocks/notifications/src/Notifications';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import UserGroups2 from '../../blocks/UserGroups2/src/UserGroups2';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import InventoryManagement2 from '../../blocks/InventoryManagement2/src/InventoryManagement2';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import ProductDescription from '../../blocks/ProductDescription/src/ProductDescription';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import ItemAvailability from '../../blocks/ItemAvailability/src/ItemAvailability';
import RefundManagement from '../../blocks/RefundManagement/src/RefundManagement';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import MultilevelApproval from '../../blocks/MultilevelApproval/src/MultilevelApproval';
import CfAddProduct from '../../blocks/CfPriceList/src/CfProductDetails.web';
import RewashOrderModal from '../../blocks/ordermanagement/src/RewashOrderModal.web';
import ImportExportErrorModal from '../../blocks/importexportdata/src/ImportExportErrorModal.web';

import PostCreation from '../../blocks/postcreation/src/PostCreation';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders';
import Orders from '../../blocks/ordermanagement/src/Orders.web';
import ProjectReporting from '../../blocks/ProjectReporting/src/ProjectReporting';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import CustomisableUserProfilesWeb from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles.web';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import RolesPermissions2Web from '../../blocks/RolesPermissions2/src/RolesPermissions2.web';
import RolesPermissionsCreate2 from '../../blocks/RolesPermissions2/src/RolesPermissionsCreate2.web';
import OrderCreation from '../../blocks/OrderCreation/src/OrderCreation2.web';
import OrderCreation2 from '../../blocks/OrderCreation/src/OrderCreation2.web';
import CfMetalProgettiSolutionIntegration2 from '../../blocks/CfMetalProgettiSolutionIntegration2/src/CfMetalProgettiSolutionIntegration2.web';
import Cfthermopatchtaggingsolutionintegration from '../../blocks/cfthermopatchtaggingsolutionintegration/src/Cfthermopatchtaggingsolutionintegration.web';
import CfStoreManagment from '../../blocks/CfStoreManagment/src/CfStoreManagment';
import PeopleManagement2 from '../../blocks/PeopleManagement2/src/PeopleManagement2';
import CfCompanyInfo from '../../blocks/CfSubCompany/src/CfCompanyInfo.web';
import CompanyView from '../../blocks/CfSubCompany/src/CompanyView.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import EmailSucess from '../../blocks/forgot-password/src/EmailSucess.web';
import Otp from '../../blocks/forgot-password/src/Otp.web';
import UpdateNewPassword from '../../blocks/forgot-password/src/UpdateNewPassword.web';
import CfPriceList from '../../blocks/CfPriceList/src/CfPriceList';
import CfViewProductDetails from '../../blocks/CfPriceList/src/CfViewProductDetails.web';
import InviteFriends from '../../blocks/invitefriends/src/InviteFriends.web';
import AddInviteFriends from '../../blocks/invitefriends/src/AddInviteFriends.web';
import ViewInviteFriends from '../../blocks/invitefriends/src/ViewInviteFriends.web';
import CfCashier from '../../blocks/cfcashier/src/Cfcashier';
import CreateProfession from '../../blocks/email-account-registration/src/CreateProfession.web';
import ProfessionList from '../../blocks/email-account-registration/src/ProfessionList';
import LoyaltyList from '../../blocks/LoyaltySystem2/src/LoyaltyList';
import StoreAdd from '../../blocks/CfStoreManagment/src/StoreAdd';
import StoreGroup from '../../blocks/CfStoreManagment/src/StoreGroupAdd';
import StoreView from '../../blocks/CfStoreManagment/src/StoreView';
import PlantAdd from '../../blocks/cfplantdashboard2/src/PlantAdd';
import PlantView from '../../blocks/cfplantdashboard2/src/PlantView';
import StoreList from '../../blocks/CfStoreManagment/src/CfStoreManagment.web';
import StoreGroupList from '../../blocks/CfStoreManagment/src/CfStoreGroupManagment.web';
import StoreGroupView from '../../blocks/CfStoreManagment/src/StoreGroup.web';
import PlantList from '../../blocks/cfplantdashboard2/src/PlantList.web';
import CustomerCreation from '../../blocks/email-account-registration/src/CustomerCreation.web';
import CustomerView from '../../blocks/email-account-registration/src/CustomerView.web';
import Gallery from '../../blocks/catalogue/src/Gallery.web';
import AddImageIcon from '../../blocks/catalogue/src/AddImageIcon.web';
import EmailAccountLoginPage from '../../blocks/email-account-login/src/EmailAccountLoginPage.web';
import SalesReporting from '../../blocks/SalesReporting/src/SalesReporting';
import Cfplantdashboard2 from '../../blocks/cfplantdashboard2/src/Cfplantdashboard2';

import AddPreference from '../../blocks/categoriessubcategories/src/AddPreference.web';
import PreferenceList from '../../blocks/categoriessubcategories/src/PreferenceList.web';
import EditPreference from '../../blocks/categoriessubcategories/src/EditPreference.web';
import ViewPreference from '../../blocks/categoriessubcategories/src/ViewPreference.web';
import ServicesList from '../../blocks/categoriessubcategories/src/ServicesList.web';
import AddServices from '../../blocks/categoriessubcategories/src/AddServices.web';
import EditServices from '../../blocks/categoriessubcategories/src/EditServices.web';
import ViewServices from '../../blocks/categoriessubcategories/src/ViewServices.web';
import CompanyList from '../../blocks/CfSubCompany/src/CompanyList.web';
import CustomerTypeList from '../../blocks/email-account-registration/src/CustomerType.web';
import CustomerTypeAdd from '../../blocks/email-account-registration/src/CustomerTypeAdd.web';
import CustomerTypeView from '../../blocks/email-account-registration/src/CustomerTypeView.web';
import CfRegionList from '../../blocks/CfRegionsManagement/src/CfRegionList.web';
import AreaList from '../../blocks/CfRegionsManagement/src/AreaList.web';
import AddRegion from '../../blocks/CfRegionsManagement/src/AddRegion.web';
import ViewRegion from '../../blocks/CfRegionsManagement/src/ViewRegion.web';
import AddArea from '../../blocks/CfRegionsManagement/src/AddArea.web';
import AreaView from '../../blocks/CfRegionsManagement/src/AreaView.web';
import AddProductSection from '../../blocks/categoriessubcategories/src/AddProductSection.web';
import EditSection from '../../blocks/categoriessubcategories/src/EditSection.web';
import ViewSection from '../../blocks/categoriessubcategories/src/ViewSection.web';
import SectionList from '../../blocks/categoriessubcategories/src/SectionList.web';
import UpchargeList from '../../blocks/categoriessubcategories/src/UpchargeList.web';
import AddUpcharge from '../../blocks/categoriessubcategories/src/AddUpcharge.web';
import AddLoyalty from '../../blocks/LoyaltySystem2/src/AddLoyalty.web';
import LoyaltyView from '../../blocks/LoyaltySystem2/src/ViewLoyalty.web';
import ViewUpcharge from '../../blocks/categoriessubcategories/src/ViewUpcharge.web';
import AddSpecification from '../../blocks/categoriessubcategories/src/AddSpecification.web';
import EditSpecification from '../../blocks/categoriessubcategories/src/EditSpecification.web';
import ViewSpecification from '../../blocks/categoriessubcategories/src/ViewSpecification.web';
import SpecificationList from '../../blocks/categoriessubcategories/src/SpecificationList.web';
import ProductPreference from '../../blocks/ProductDescription/src/ProductPreference.web';
import TaxList from '../../blocks/TaxCalculator/src/TaxList.web';
import AddEditTax from '../../blocks/TaxCalculator/src/AddEditTax.web';
import ViewTax from '../../blocks/TaxCalculator/src/ViewTax.web';
import OrganizationList from '../../blocks/email-account-registration/src/OrganizationList.web';
import AddOrganization from '../../blocks/email-account-registration/src/AddOrganization.web';
import OrganizationListView from '../../blocks/email-account-registration/src/OrganizationListView.web';
import PromoCodeList from '../../blocks/promocodes/src/PromoCodeList.web';
import PromoCodeCreate from '../../blocks/promocodes/src/PromoCodeCreate.web';
import PromoCodeView from '../../blocks/promocodes/src/PromoCodeView.web';
import PriceList from '../../blocks/CfPriceList/src/PriceList.web';
import PriceListView from '../../blocks/CfPriceList/src/PriceListView.web';
import HomeCleaningProduct from '../../blocks/catalogue/src/HomeCleaningProduct.web';
import AddHomeCleaningProduct from '../../blocks/catalogue/src/AddHomeCleaningProduct.web';
import HomeCleaningSettings from '../../blocks/ProductDescription/src/HomeCleaningSettings.web';
import HomeCleaningSettingsView from '../../blocks/ProductDescription/src/HomeCleaningSettingsView.web';
import HomeCleaningOrders from '../../blocks/ordermanagement/src/HomeCleaningOrders.web';
import HomeCleaningSettingsList from '../../blocks/ProductDescription/src/HomeCleaningSettingsList.web';
import AccountCreation from '../../blocks/email-account-registration/src/AccountCreation.web';
import StoreKeeper from '../../blocks/cfcashier/src/StoreKeeper.web';
import OutGoingTransaction from '../../blocks/cfcashier/src/OutGoingTransaction.web';
import TransferAmount from '../../blocks/cfcashier/src/TransferAmount.web';
import OutGoingTransactionDetails from '../../blocks/cfcashier/src/OutGoingTransactionDetails.web';
import IncomingTransaction from '../../blocks/cfcashier/src/IncomingTransaction.web';
import CashierStoreList from '../../blocks/dashboard/src/StoreList.web';
import CustomerDashboard from '../../blocks/dashboard/src/CustomerDashboard.web';
import PlantReport from '../../blocks/dashboard/src/PlantReport.web';
import Cfsalesdashboard3 from '../../blocks/cfsalesdashboard3/src/Cfsalesdashboard3.web';
import MainDashboard from '../../blocks/dashboard/src/MainDashboard.web';
import PopularTimesDashboard from '../../blocks/dashboard/src/PopularTimesDashboard.web';
import SalesDashboard from '../../blocks/cfsalesdashboard3/src/SalesDashboard.web';
import DashboardOverview from '../../blocks/dashboard/src/DashboardOverview.web';
import Print from '../../blocks/print/src/Print.web';
import DiscountCreditSection from '../../blocks/dashboard/src/DiscountCreditSection.web';
import RevenueDashboard from '../../blocks/dashboard/src/RevenueDashboard.web';
import AccountList from '../../blocks/email-account-registration/src/AccountList';
import ZatcaHistory from '../../blocks/ordermanagement/src/ZatcaHistory.web';
import Layout from '../../blocks/navigationmenu/src/Layout.web';
import B2bBusinessAccountList from '../../blocks/cfcontractmanagement7/src/Cfb2bbussingessAccountList.web';
import Cflabelprinters3 from '../../blocks/cflabelprinters3/src/Cflabelprinters3.web';

const routeMap = {
  Cflabelprinters3: {
    component: Cflabelprinters3,
    path: '/LabelPrinting'
  },
  ZatcaHistory: {
    component: ZatcaHistory,
    path: '/ZatcaHistory',
  },
  MetalProgetti: {
    component: CfMetalProgettiSolutionIntegration2,
    path: '/Hardware-MetalProgetti'
  },
  ReceiptPrinter: {
    component: Print,
    path: '/Hardware-ReceiptPrinter'
  },
  GarmentTagPrinter: {
    component: Cfthermopatchtaggingsolutionintegration,
    path: '/Hardware-GarmentTagPrinter'
  },
  Print: {
    component: Print,
    path: '/Print',
  }, 
  DashboardOverview: {
    component: DashboardOverview,
    path: '/Dashboard-Overview'
  },
  SalesDashboard: {
    component: SalesDashboard,
    path: '/Dashboard-Sales'
  },
  PlantReport: {
    component: PlantReport,
    path: '/Dashboard-PlantReport'
  },
  MainDashboard: {
    component: MainDashboard,
    path: '/Dashboard-Main'
  },
  B2bBusinessAccountList: {
    component: B2bBusinessAccountList,
    path: '/B2bBusinessAccountList'
  },
  PopularTimesDashboard: {
    component: PopularTimesDashboard,
    path: '/Dashboard-PopularTimes'
  },
  DiscountCreditSection: {
    component: DiscountCreditSection,
    path: '/Dashboard-DiscountCredit'
  },
  RevenueDashboard: {
    component: RevenueDashboard,
    path: '/Dashboard-Revenue'
  },
  CashierStoreList: {
    component: CashierStoreList,
    path: '/CashierStoreList'
  },
  OutGoingTransaction: {
    component: OutGoingTransaction,
    path: '/cashier-OutGoingTransaction'
  },
  OutGoingTransactionDetails: {
    component: OutGoingTransactionDetails,
    path: '/TransactionDetails/:pageType/:navigationBarTitleText'
  },
  IncomingTransaction: {
    component: IncomingTransaction,
    path: '/cashier-IncomingTransaction'
  },
  TransferAmount: {
    component: TransferAmount,
    path: '/cashier-TransferAmount'
  },
  HomeCleaningSettingsList: {
    component: HomeCleaningSettingsList,
    path: '/HomeCleaning-SettingsList'
  },
  HomeCleaningSettings: {
    component: HomeCleaningSettings,
    path: '/HomeCleaning-Settings'
  },
  HomeCleaningSettingsEdit: {
    component: HomeCleaningSettings,
    path: '/HomeCleaning-SettingsEdit/:navigationBarTitleText'
  },
  HomeCleaningSettingsView: {
    component: HomeCleaningSettingsView,
    path: '/HomeCleaning-SettingsView/:navigationBarTitleText'
  },
  HomeCleaningOrders: {
    component: HomeCleaningOrders,
    path: '/HomeCleaning-Orders'
  },
  PromoCodeView: {
    component: PromoCodeView,
    path: '/PromoCode-View/:id'
  },
  PromoCodeEdit: {
    component: PromoCodeCreate,
    path: '/PromoCode-Edit/:id'
  },
  PromoCodeCreate: {
    component: PromoCodeCreate,
    path: '/PromoCode-Create'
  },
  PromoCode: {
    component: PromoCodeList,
    path: '/PromoCode'
  },
  PriceList: {
    component: PriceList,
    path: '/Products-PriceList'
  },
  PriceListView: {
    component: PriceListView,
    path: '*/pricelist-view/:id',
    modal: true
  },
  RewashOrderModal: {
    component: RewashOrderModal,
    path: '*/rewashorder-modal',
    modal: true
  },

  HomeCleaningProductList: {
    component: HomeCleaningProduct,
    path: '/HomeCleaningProductList'
  },
  CreateHomeCleaningProduct: {
    component: AddHomeCleaningProduct,
    path: '/CreateHomeCleaningProduct'
  },
  EditHomeCleaningProduct: {
    component: AddHomeCleaningProduct,
    path: '/CreateHomeCleaningProduct-Edit/:productId'
  },
  ViewHomeCleaningProduct: {
    component: AddHomeCleaningProduct,
    path: '/CreateHomeCleaningProduct-View/:productId'
  },
  Cfplantdashboard2: {
    component: Cfplantdashboard2,
    path: '/Cfplantdashboard2'
  },
  ProjectReporting: {
    component: ProjectReporting,
    path: '/ProjectReporting'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfilesWeb,
    path: '/CustomisableUserProfiles'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },

  Orders: {
    component: Orders,
    path: '/StoreManage-Orders'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: '/TaskAllocator'
  },
  LoyaltySystem2: {
    component: LoyaltySystem2,
    path: '/LoyaltySystem2'
  },
  TaskViews: {
    component: TaskViews,
    path: '/TaskViews'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  BarcodesScanner: {
    component: BarcodesScanner,
    path: '/BarcodesScanner'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '*/ImportExportData',
    modal: true
  },
  ImportExportErrorModal: {
    component: ImportExportErrorModal,
    path: '*/ImportExportErrorModal',
    modal: true
  },
  MultipleCurrencySupport: {
    component: MultipleCurrencySupport,
    path: '/MultipleCurrencySupport'
  },
  OrganisationHierarchy: {
    component: OrganisationHierarchy,
    path: '/OrganisationHierarchy'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  HelpdeskReporting: {
    component: HelpdeskReporting,
    path: '/HelpdeskReporting'
  },
  TaxCalculator: {
    component: TaxCalculator,
    path: '/TaxCalculator'
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders'
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  ExpressDelivery: {
    component: ExpressDelivery,
    path: '/ExpressDelivery'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  CfWhatsappApi: {
    component: CfWhatsappApi,
    path: '/CfWhatsappApi'
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2'
  },
  RolesPermissions: {
    component: RolesPermissions2Web,
    path: '/Settings-RolesPermissions'
  },
  RolesPermissionsCreate2: {
    component: RolesPermissionsCreate2,
    path: '/Settings-RolesPermissionsCreate2'
  },
  RolesPermissionsEdit2: {
    component: RolesPermissionsCreate2,
    path: '/Settings-RolesPermissionsEdit2/:roleId'
  },
  RolesPermissionsView2: {
    component: RolesPermissionsCreate2,
    path: '/Settings-RolesPermissionsView2/:roleId'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  CfStoreManagment: {
    component: CfStoreManagment,
    path: '/CfStoreManagment'
  },
  AgentDashboard2: {
    component: AgentDashboard2,
    path: '/AgentDashboard2'
  },
  QrCodes: {
    component: QrCodes,
    path: '/QrCodes'
  },
  AccountTransfer: {
    component: AccountTransfer,
    path: '/AccountTransfer'
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/Marketing-Customer-PeopleManagement2'
  },
  Notes: {
    component: Notes,
    path: '/Notes'
  },
  Couponcodegenerator: {
    component: Couponcodegenerator,
    path: '/Couponcodegenerator'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: '/HelpCentreQA'
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: '/HelpCentreSub'
  },
  Geofence: {
    component: Geofence,
    path: '/Geofence'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  CfSubCompany: {
    component: CfSubCompany,
    path: '/CfSubCompany'
  },
  CfCompanyInfo: {
    component: CfCompanyInfo,
    path: '/CfCompanyInfo'
  },
  CompanyList: {
    component: CompanyList,
    path: '/Settings-General-CompanyList'
  },
  CustomerType: {
    component: CustomerTypeList,
    path: '/Marketing-CustomerType'
  },
  CustomerTypeAdd: {
    component: CustomerTypeAdd,
    path: '/CustomerTypeAdd'
  },
  CustomerTypeView: {
    component: CustomerTypeView,
    path: '/CustomerTypeView/:id'
  },

  CustomerTypeEdit: {
    component: CustomerTypeAdd,
    path: '/CustomerTypeEdit/:id'
  },
  CompanyView: {
    component: CompanyView,
    path: '/Settings-General-CompanyListView'
  },
  CompanyCreation: {
    component: CfCompanyInfo,
    path: '/Settings-General-CompanyListAdd'
  },
  CompanyEdit: {
    component: CfCompanyInfo,
    path: '/Settings-General-CompanyListEdit'
  },
  TicketSystem: {
    component: TicketSystem,
    path: '/TicketSystem'
  },
  OfflineWork: {
    component: OfflineWork,
    path: '/OfflineWork'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  EmailSucess: {
    component: EmailSucess,
    path: '/EmailSucess'
  },
  Otp: {
    component: Otp,
    path: '/Otp'
  },
  UpdateNewPassword: {
    component: UpdateNewPassword,
    path: '/UpdateNewPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  ServiceSpecificSettingsAdmin: {
    component: ServiceSpecificSettingsAdmin,
    path: '/ServiceSpecificSettingsAdmin'
  },
  Crm3rdPartyIntegration: {
    component: Crm3rdPartyIntegration,
    path: '/Crm3rdPartyIntegration'
  },
  CfAddProduct: {
    component: CfAddProduct,
    path: '/Products-ProductListAdd',
    exact: true
  },
  CfEditProduct: {
    component: CfAddProduct,
    path: '/Products-ProductListEdit/:productId',
    exact: true
  },
  CfViewProduct: {
    component: CfViewProductDetails,
    path: '/Products-ProductListView/:productId',
    exact: true
  },
  ProductList: {
    component: CfPriceList,
    path: '/Products-ProductList'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  AmazonPayIntegration: {
    component: AmazonPayIntegration,
    path: '/AmazonPayIntegration'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  Invitefriends: {
    component: InviteFriends,
    path: '/Marketing-Invitefriends'
  },
  AddInvitefriends: {
    component: AddInviteFriends,
    path: '/Marketing-AddInvitefriends'
  },
  EditInvitefriends: {
    component: AddInviteFriends,
    path: '/Marketing-EditInvitefriends/:id'
  },
  ViewInviteFriends: {
    component: ViewInviteFriends,
    path: '/Marketing-ViewInviteFriends/:id'
  },
  ItemAvailability: {
    component: ItemAvailability,
    path: '/ItemAvailability'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  MultilevelApproval: {
    component: MultilevelApproval,
    path: '/MultilevelApproval'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  UserGroups2: {
    component: UserGroups2,
    path: '/UserGroups2'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  InventoryManagement2: {
    component: InventoryManagement2,
    path: '/InventoryManagement2'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  ProductDescription: {
    component: ProductDescription,
    path: '/ProductDescription'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  BarcodeSettings: {
    component: BarcodeSettings,
    path: '/BarcodeSettings'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  ItemGrouper: {
    component: ItemGrouper,
    path: '/ItemGrouper'
  },
  Blockedusers: {
    component: Blockedusers,
    path: '/Blockedusers'
  },
  AddBlockeduser: {
    component: AddBlockeduser,
    path: '/AddBlockeduser'
  },
  ReceiptCustomisation: {
    component: ReceiptCustomisation,
    path: '/ReceiptCustomisation'
  },
  CfCashier: {
    component: CfCashier,
    path: '/cashier'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  CfRegionsManagement: {
    component: CfRegionsManagement,
    path: '/CfRegionsManagement'
  },
  RegionList: {
    component: CfRegionList,
    path: '/Settings-General-RegionList'
  },
  AreaList: {
    component: AreaList,
    path: '/Settings-General-AreaList'
  },
  AddRegion: {
    component: AddRegion,
    path: '/Settings-General-RegionListAdd'
  },
  RegionEdit: {
    component: AddRegion,
    path: '/Settings-General-RegionListEdit'
  },
  RegionView: {
    component: ViewRegion,
    path: '/Settings-General-RegionListView'
  },
  AddArea: {
    component: AddArea,
    path: '/Settings-General-AreaListAdd'
  },
  ViewArea: {
    component: AreaView,
    path: '/Settings-General-AreaListView'
  },
  AreaEdit: {
    component: AddArea,
    path: '/Settings-General-AreaListEdit'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Surveys: {
    component: Surveys,
    path: '/Surveys'
  },
  AddImageIcon: {
    component: AddImageIcon,
    path: '/Products-GalleryAddImageIcon'
  },
  Gallery: {
    component: Gallery,
    path: '/Products-Gallery'
  },

  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Account: {
    component: AccountList,
    path: '/Settings-Account'
  },
  ProfessionList: {
    component: ProfessionList,
    path: '/Marketing-ProfessionList'
  },
  StoreList: {
    component: StoreList,
    path: '/Settings-General-StoreList'
  },
  StoreAdd: {
    component: StoreAdd,
    path: '/Settings-General-StoreListAdd'
  },
  StoreEdit: {
    component: StoreAdd,
    path: '/Settings-General-StoreListEdit'
  },
  StoreView: {
    component: StoreView,
    path: '/Settings-General-StoreListView'
  },

  StoreGroupList: {
    component: StoreGroupList,
    path: '/Settings-General-StoreGroupList'
  },
  StoreGroupAdd: {
    component: StoreGroup,
    path: '/Settings-General-StoreGroupListAdd'
  },
  StoreGroupEdit: {
    component: StoreGroup,
    path: '/Settings-General-StoreGroupListEdit/:id'
  },
  StoreGroupView: {
    component: StoreGroupView,
    path: '/Settings-General-StoreGroupListView/:id'
  },

  PlantList: {
    component: PlantList,
    path: '/Settings-General-PlantList'
  },
  PlantAdd: {
    component: PlantAdd,
    path: '/Settings-General-PlantListAdd'
  },
  PlantEdit: {
    component: PlantAdd,
    path: '/Settings-General-PlantListEdit'
  },
  PlantView: {
    component: PlantView,
    path: '/Settings-General-PlantListView'
  },
  AccountCreation: {
    component: AccountCreation,
    path: '/Settings-AccountCreation'
  },
  AccountEdit: {
    component: AccountCreation,
    path: '/Settings-AccountEdit/:id'
  },
  AccountView: {
    component: AccountCreation,
    path: '/Settings-AccountView/:id'
  },
  CreateProfession: {
    component: CreateProfession,
    path: '/Marketing-ProfessionList-CreateProfession'
  },
  ProfessionEdit: {
    component: CreateProfession,
    path: '/Marketing-ProfessionList-ProfessionEdit'
  },
  ProfessionView: {
    component: CreateProfession,
    path: '/Marketing-ProfessionList-ProfessionView'
  },
  ApplePayIntegration: {
    component: ApplePayIntegration,
    path: '/ApplePayIntegration'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: '/ElasticSearch'
  },
  PerformanceTracker: {
    component: PerformanceTracker,
    path: '/PerformanceTracker'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  Archive: {
    component: Archive,
    path: '/Archive'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  StoreLocator: {
    component: StoreLocator,
    path: '/StoreLocator'
  },
  SalesReporting: {
    component: SalesReporting,
    path: '/SalesReporting'
  },

  EmailAccountLoginPage: {
    component: EmailAccountLoginPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  AddProductSection: {
    component: AddProductSection,
    path: '/Products-SectionListAdd'
  },
  AddPreference: {
    component: AddPreference,
    path: '/Products-PreferenceListAdd'
  },
  PreferenceList: {
    component: PreferenceList,
    path: '/Products-PreferenceList'
  },
  SectionList: {
    component: SectionList,
    path: '/Products-SectionList'
  },
  EditPreference: {
    component: EditPreference,
    path: '/Products-PreferenceListEdit/:name'
  },
  ViewPreference: {
    component: ViewPreference,
    path: '/Products-PreferenceListView/:name'
  },
  ServicesList: {
    component: ServicesList,
    path: '/Products-ServicesList'
  },
  LoyaltyList: {
    component: LoyaltyList,
    path: '/Marketing-LoyaltyList'
  },
  OrganizationList: {
    component: OrganizationList,
    path: '/Marketing-Customer-OrganizationList'
  },
  AddOrganization: {
    component: AddOrganization,
    path: '/Marketing-Customer-OrganizationList-add-organization'
  },
  EditOrganization: {
    component: AddOrganization,
    path: '/Marketing-Customer-OrganizationList-edit-organization/:organizationId'
  },
  ViewOrganization: {
    component: OrganizationListView,
    path: '/Marketing-Customer-OrganizationList-view-organization/:organizationId'
  },
  AddLoyalty: {
    component: AddLoyalty,
    path: '/Marketing-LoyaltyList-addLoyalty'
  },
  EditLoyalty: {
    component: AddLoyalty,
    path: '/Marketing-LoyaltyList-EditLoyalty'
  },
  LoyaltyView: {
    component: LoyaltyView,
    path: '/LoyaltyView'
  },
  AddServices: {
    component: AddServices,
    path: '/Products-ServicesListAdd'
  },
  EditServices: {
    component: EditServices,
    path: '/Products-ServicesListEdit/:id'
  },
  ViewServices: {
    component: ViewServices,
    path: '/Products-ServicesListView/:id'
  },
  EditSection: {
    component: EditSection,
    path: '/Products-SectionListEdit/:id'
  },
  ViewSection: {
    component: ViewSection,
    path: '/Products-SectionListView/:id'
  },

  UpchargeList: {
    component: UpchargeList,
    path: '/Products-UpchargeList'
  },
  AddUpcharge: {
    component: AddUpcharge,
    path: '/Products-UpchargeListAdd'
  },
  EditUpcharge: {
    component: AddUpcharge,
    path: '/Products-UpchargeListEdit/:navigationBarTitleText'
  },
  ViewUpcharge: {
    component: ViewUpcharge,
    path: '/Products-UpchargeListView/:upchargeId'
  },
  SpecificationList: {
    component: SpecificationList,
    path: '/Products-SpecificationList'
  },
  AddSpecification: {
    component: AddSpecification,
    path: '/Products-SpecificationListAdd'
  },
  EditSpecification: {
    component: EditSpecification,
    path: '/Products-SpecificationListEdit/:navigationBarTitleText'
  },
  ViewSpecification: {
    component: ViewSpecification,
    path: '/Products-SpecificationListView/:navigationBarTitleText'
  },
  TaxList: {
    component: TaxList,
    path: '/Settings-General-Taxlist'
  },
  AddEditTax: {
    component: AddEditTax,
    path: '/Settings-General-TaxlistAdd/:id'
  },
  ViewTax: {
    component: ViewTax,
    path: '/Settings-General-TaxlistView/:id'
  },
  EditQuickDrop: {
    component: OrderCreation2,
    path: '/StoreManage-EditQuickDrop/:editId'
  },
  OrderCreation: {
    component: OrderCreation2,
    path: '/StoreManage-OrderCreation'
  },
  OrderCreation2: {
    component: OrderCreation,
    path: '/StoreManage-OrderCreation2'
  },
  ProductPreference: {
    component: ProductPreference,
    path: '/product-preference'
  },
  CustomerCreation: {
    component: CustomerCreation,
    path: '/Marketing-Customer-PeopleManagement2-customercreation'
  },
  CustomerView: {
    component: CustomerView,
    path: '/Marketing-Customer-PeopleManagement2-customerview/:customerId'
  },
  CustomerEdit: {
    component: CustomerCreation,
    path: '/Marketing-Customer-PeopleManagement2-customeredit/:customerId'
  },
  StoreKeeperWithTab: {
    component: StoreKeeper,
    path: '/cashup/:navigationBarTitleText',
    exact: true
  },
  StoreKeeper: {
    component: StoreKeeper,
    path: '/cashup',
    exact: true
  },
  CustomerDashboard: {
    component: CustomerDashboard,
    path: '/Dashboard-Customer',
    exact: true
  },
  SectionSales: {
    component: Cfsalesdashboard3,
    path: '/Dashboard-SectionSales'
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <Print snackbarMode />
        {window.location.pathname !== '/' ? (
          <Layout routeMap={routeMap}>
            {WebRoutesGenerator({ routeMap })}
          </Layout>
        ) : (
          WebRoutesGenerator({ routeMap })
        )}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
