Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcontractmanagement7";
exports.labelBodyText = "cfcontractmanagement7 Body";

exports.btnExampleTitle = "CLICK ME";

exports.strings = {
  b2bBussinessListString: {
    headingTitle: "Business Accounts",
    exportimport: "Export/Import",
    addAccount: "Add Account",
    tabelString_customerName: "Customer Name",
    tabelString_email: "Email",
    tabelString_phoneNo: "Phone No.",
    tabelString_company: "Company",
    tabelString_priceList: "Price List",
    tabelString_startDate: "Start Date",
    tabelString_endDate: "End Date",
    tabelString_status: "Status",
    tabelString_noAccountDataFound: "No Account Data Found",
    tabelString_viewStatus: "View Status",
    noPermissions: "No Permissions",
    view: "View",
    delete: "Delete",
    renew: "Renew",
    exportimportText: "Export/Import",
  },
};
// Customizable Area End