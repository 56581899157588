import React from "react";
// Customizable Area Start
import {
  Box,
  Table,
  Paper,
  styled,
  Button,
  Popover,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";

import SortingTableCell from "../../../components/src/SortingTableCell";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";

import PriceListController, {
  configJSON
} from "./PriceListController.web";

const Strings = configJSON.Strings.priceCodeList;

import { style } from "./PriceListStyles";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import AddPriceListModal from "./AddPricelistModal.web";
import FilterPopover from "../../../components/src/FilterPopover";
import Loader, { LoadingComponent } from "../../../components/src/Loader.web";
import {
  renderBaseOnConditions,
  sortStringCondition
} from "../../../blocks/utilities/src/CustomBlockHelpers";
const theme = createTheme({});
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";

// Customizable Area End

class PriceList extends PriceListController {
  // Customizable Area Start
  getPopoverData() {
    const { PriceItem, openAction, permissionStatus } = this.state;
    const open = Boolean(this.state.openAction);
    const popOverId = open ? "simple-popover" : undefined;
    const classes = this.props.classes;    

    return (
      <ActionPopover
        data-test-id="popver"
        style={{boxShadow: "none"}}
        id={popOverId}
        open={open}
        anchorEl={openAction}
        onClose={this.handleClosePopver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          renderBaseOnConditions(
            (
              permissionStatus.viewPermission ||
              permissionStatus.activatePermission ||
              permissionStatus.deactivatePermission
            ),
            <div className={classes.popoverContainer}>
              {
                renderBaseOnConditions(
                  permissionStatus.viewPermission,
                  <Box
                    data-test-id='viewButton'
                    onClick={() => this.handleNavihateViewModal(PriceItem.id)}
                    className={classes.popoverButton}
                  >
                    {Strings.view}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  permissionStatus.deactivatePermission && PriceItem.attributes.activated,
                  <Box
                    data-test-id='deactiveButton'
                    onClick={() => this.handleDeactivePrice(PriceItem.attributes)}
                    className={classes.popoverButton}
                  >
                    {Strings.deactivate}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  permissionStatus.activatePermission as boolean && !PriceItem.attributes.activated,
                  <Box
                    data-test-id='activeButton'
                    onClick={() => this.handleDeactivePrice(PriceItem.attributes)}
                    className={classes.popoverButton}
                  >
                    {Strings.active}
                  </Box>,
                  <></>
                )
              }
            </div>,
            <div className={classes.popoverContainer}>
              <Box className="noPermissions">
                No Permissions
              </Box>
            </div>
          )
        }
      </ActionPopover>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      sortingData,
      PriceList,
      permissionStatus
    } = this.state;
    const classes = this.props.classes;
    const open = Boolean(this.state.openAction);
    const popOverId = open ? "simple-popover" : undefined;
    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <>
          <Box>
            <div className={`${classes.headerWrapper} ${classes.tableContainer}`}>
              <PageTitle>{Strings.pageTitle}</PageTitle>
              <div className={classes.headerButtonPartAccount}>
                <div
                  data-test-id='filterBtn'
                  // className={classes.filterButtonAccount}
                  className={sortStringCondition(
                    this.state.isAppliedFilter,
                    `${classes.filterButtonAccount} ${classes.activeBorder}`,
                    classes.filterButtonAccount
                  )}
                  onClick={(event) =>
                    this.setState({
                      filterAnchor: event.currentTarget,
                    })
                  }
                >
                  <FilterIcon color={this.handleReturnColorType()} />
                  {renderBaseOnConditions(
                    this.state.isAppliedFilter,
                    <div className={classes.filterActiveIcon}></div>,
                    <></>
                  )}
                </div>
                <LoadingComponent
                  isLoading={this.state.isLoadingPermission}
                  height={44}
                  width={160}
                  isAllowed={permissionStatus.createPermission}
                  children={
                    <Button
                      style={{ height: 44, fontWeight: 600 }}
                      data-test-id='addPriceList'
                      className={classes.addButton}
                      onClick={() =>
                        this.setState({ openAddPriceListModal: true })
                      }
                    >
                      {Strings.addPriceList}
                    </Button>
                  }
                />

                <FilterPopover
                  onClose={() => this.setState({ filterAnchor: undefined })}
                  anchor={this.state.filterAnchor as HTMLDivElement | undefined}
                  onAutoCompleteChange={
                    (title: string, value: string) => this.handleFilterAutoSugg(title, value)
                  }
                  onFilterChange={this.handleFilterChange}
                  filters={this.state.filters}
                />
              </div>
            </div>
            <TableContainer component={Paper} className={`${classes.tableContainer} ${classes.scrollable}`}>
              <Table className={classes.table} aria-label='customized table'>
                <TableHead>
                  <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                    <SortingTableHeader
                      sortingData={sortingData}
                      title={Strings.priceListName}
                      sortingKey='name'
                      {...this.sortingProps}
                      type='left'
                      width="22%"
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title={Strings.priceListType}
                      sortingKey='price_list_type'
                      {...this.sortingProps}
                      width="20%"
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title={Strings.priceListCopy}
                      sortingKey='price_list_to_copy'
                      {...this.sortingProps}
                      width="23%"
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title={Strings.addedBy}
                      sortingKey='product_currency_id'
                      {...this.sortingProps}
                      width="15%"
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title={Strings.status}
                      sortingKey='activated'
                      {...this.sortingProps}
                      width="15%"
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title=''
                      type='action'
                      sortingKey=''
                      {...this.sortingProps}
                      width="5%"
                      sortingDisabled
                    />
                  </TableRow>
                </TableHead>
                {this.state.isLoading ? <>{Array(5)
                      .fill(1)
                      .map((_, index) => (
                        <TableBody key={index} data-test-id='tableData'>
                          <TableRow>
                          {[...Array(5)].map((_, cellIndex) => (
                             <TableRowsSkeleton key={cellIndex} />
                            ))}   
                          </TableRow>
                        </TableBody>
                      ))}</> : 
                <TableBody>
                  {PriceList.length !== 0 ? PriceList.map((item) => {
                    return (
                      <TableRow
                        key={"table-row-" + item.id}
                        data-test-id={"table-row-" + item.id}
                      >
                        <SortingTableCell
                          width='22vw'
                          type='left'
                          content={item.attributes.name}
                        />
                        <SortingTableCell
                          width='20vw'
                          type='middle'
                          content={item.attributes.price_list_type}
                        />
                        <SortingTableCell
                          width='23vw'
                          type='middle'
                          content={item.attributes.price_list_to_copy}
                        />
                        <SortingTableCell
                          width='15vw'
                          type='middle'
                          content={item.attributes.add_by}
                        />
                        <SortingTableCell
                          width='15vw'
                          type='right'
                          content={
                            item.attributes.activated
                              ? Strings.active
                              : Strings.deactivated
                          }
                        />
                        <SortingTableCell
                          width='5vw'
                          type='action'
                          content={
                            <IconButton
                              data-test-id={"moreButton-" + item.id}
                              aria-describedby={popOverId}
                              onClick={(event) => this.handlepopver(event, item)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                      </TableRow>
                    );
                  }) : <TableRow><TableCell colSpan={5}><NoRecordFoundText>{Strings.noRecordFounds}</NoRecordFoundText></TableCell></TableRow>}
                </TableBody>}
              </Table>

            </TableContainer>

            {PriceList.length != 0 && (
              <TablePagination
                onPageChange={(event, page) => {this.handlePageChange(page)}}
                count={this.state.meta.total_count}
                component='div'
                data-test-id='pagination'
                page={this.state.page}
                rowsPerPage={this.rowPerPage}
                rowsPerPageOptions={[10]}
                className={`${classes.tableContainer} ${classes.scrollable}`}
              />
            )}
          </Box>
          {this.getPopoverData()}
          <ConfirmationModal
            data-test-id="deactiveModel"
            open={this.state.openDeactiveModel}
            handleClose={this.handleCloseConfirmModal}
            handleConfirm={this.deActivatePrice}
            title={Strings.conformDeactiveText}
            message={{
              id: this.state.deActivatedItem.id, 
              title: this.state.deActivatedItem.name
            }}
            confirmText="Deactivate"
            dynamic={true}
          />
          <ConfirmationModal
            data-test-id="confirmDeactiveModel"
            open={this.state.deactivated}
            handleClose={this.handleCloseConfirmModal}
            handleConfirm={this.handleBackToListing}
            title={Strings.pricelistDeactivateMessage}
            message={{
              id: this.state.deActivatedItem.id, 
              title: this.state.deActivatedItem.name
            }}
            confirmText={Strings.confirmButtonText}
            displayItem={true}
          />
        </>
        <AddPriceListModal 
          open={this.state.openAddPriceListModal}
          onClose={this.closeAddPriceListModal} 
          navigation={undefined} 
          id={""} 
          classes={undefined}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const NoRecordFoundText = styled("div")({
  fontSize: 16,
  fontWeight: 600,
  textAlign: "center",
});

const PageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px"
  },
});

const ActionPopover = styled(Popover)({
  "& .noPermissions": {
    padding: "0 8px"
  }
});

export default withStyles(style)(PriceList);
// Customizable Area End

