// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import { IFilter } from "../../../components/src/FilterPopover";
import { makeApiMessage } from "../../../components/src/common";
import moment from "moment";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { handleDisplayRegion } from "./utils";
import html2pdf from "html2pdf.js";
import { customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus } from "../../utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export type OrderCountByHour = {
  time: string;
  count: number;
  total_pieces: number;
};
export type OrderCountByDay = {
  day: string;
  count: number;
  total_pieces: number;
};
const initialRange = {
  start_date: "",
  end_date: "",
  start_time: "",
  end_time: "",
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingHourData : boolean;
  loadingDayData: boolean;
  ordersByHour: OrderCountByHour[]
  ordersByDay: OrderCountByDay[]
  activeType: "Store" | "Mobile"
  filterAnchor: undefined | HTMLDivElement
  filters: IFilterItem[]
  regionId: string
  storeId: string
  storeName: string
  groupId: string
  areaId: string
  groupName: string
  regionName: string
  currentRange: {
    start_date: string
    end_date: string
    start_time: string
    end_time: string
  }
  openExportModal: boolean
  isPrinting: boolean
  permissionStatus: DashboardPermissionStatus | null;
  isAppliedFilter: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PopularTimesDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPopularByHoursApiCallId = "getPopularByHoursApiCallId";
  getPopularByDaysApiCallId = "getPopularByDaysApiCallId";
  exportTimer: NodeJS.Timeout | undefined = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      regionId: "",
      regionName: "",
      storeId: "",
      storeName: "",
      groupId:  "",
      areaId: "",
      groupName:  "",
      filterAnchor: undefined,
      filters: [
        {
          title: "Date",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          value: "today",
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last_7_days",
            },
            {
              label: "Last 30 days",
              value: "last_30_days",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ],
        },
        {
          title: "Time",
          type: "timeselect",
          timeRange: {from: "", to: ""},
        }
      ],
      ordersByHour: ["12 AM", 
        "1 AM", 
        "2 AM", 
        "3 AM", 
        "4 AM", 
        "5 AM", 
        "6 AM", 
        "7 AM", 
        "8 AM", 
        "9 AM", 
        "10 AM", 
        "11 AM", 
        "12 AM", 
        "1 PM", 
        "2 PM", 
        "3 PM", 
        "4 PM", 
        "5 PM",
        "6 PM",
        "7 PM",
        "8 PM",
        "9 PM",
        "10 PM",
        "11 PM",
      ].map(time => ({time, count: 0, total_pieces: 0})),
      ordersByDay: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].map(day => ({day, count: 0, total_pieces: 0})),
      loadingHourData: true,
      loadingDayData: true,
      activeType: "Store",
      currentRange: initialRange,
      openExportModal: false,
      isPrinting: false,
      permissionStatus: null,
      isAppliedFilter: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveDataFromLayout(message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getPopularByDaysApiCallId:
          const {data = [], meta = {}} = responseJson
          this.setState({
            ordersByDay: data, 
            currentRange: {
              ...initialRange,
              ...meta
            },
            loadingDayData: false
          });
          break;
        case this.getPopularByHoursApiCallId:
          this.setState({ordersByHour: responseJson?.data.map((item: OrderCountByHour) => ({...item, time: item.time.replace(/^0+/, '')})) || [], loadingHourData: false});
          break;
        default:
          break;
      }
    
    }
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const receivedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      const {areaId,groupId,regionMultiId, storeId, regionNames,regionSelectAll} = receivedData
      this.setState({
        groupId:groupId,
        storeId,
        areaId,
        regionId: regionMultiId,
        regionName: handleDisplayRegion(regionMultiId,regionNames,regionSelectAll).textDisplayed
      },()=> {if(regionMultiId) this.configureStorageFilter()})
    }
    // Customizable Area End
  }

  // Customizable Area Start

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }
  
  switchType = (newType: "Store" | "Mobile") => this.setState({activeType: newType}, () => this.fetchData())

  handleFilterButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      filterAnchor: event.currentTarget,
    });
  };

  handleCloseFilterButton = () => {
    this.setState({
      filterAnchor: undefined,
    });
  };

  handleFilterChange = (filters: IFilter[]) =>  {
    if (this.checkIsFilterApplied(filters,"today")) {
      localStorage.setItem("PopularDash_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("PopularDash_filter_value");
    };
    const  newFilters = filters.map(item => {
      if (item.type === "dateselect"  && item.value === "specific" && (!item.datevalue?.from || !item.datevalue.to)) return ({
        ...item,
        value: "today",
        datevalue: {
          from: "",
          to: ""
        }
      })
      if (item.type === "timeselect" && (!item.timeRange?.from || !item.timeRange?.to)) return ({
        ...item,
        timeRange: {
          from: "",
          to: ""
        }
      })
      return item
    })
    this.setState({filters: newFilters, isAppliedFilter: this.checkIsFilterApplied(filters,"today")}, () => this.fetchData())
  };

  fetchData = () => {
    this.getPopularData("day_wise")
    this.getPopularData("time_wise")
  }

  getPopularData = (type: "day_wise" | "time_wise") => {
    const requestMessage = makeApiMessage({
      url: configJSON.popularTimesEndPoint + `?${type}=true${this.getFilterQueryString()}`,
      method: configJSON.dashboarApiMethodType
    })
    if (type === "day_wise") {
      this.getPopularByDaysApiCallId = requestMessage. messageId
      this.setState({loadingDayData: true})
    } else {
      this.getPopularByHoursApiCallId = requestMessage.messageId;
      this.setState({loadingHourData: true})
    }
    this.send(requestMessage)
  }

  getFilterQueryString = () => {
    const {storeId, regionId, filters, activeType,groupId,areaId} = this.state
    const {datevalue, value} = filters[0] || {}
    const {timeRange} = filters[1] || {}
    const regionParam = regionId && !storeId ? `&region_ids=${regionId}` : ""

    let storeParam = storeId ? `&store_ids=${storeId}`: ""
     const groupParam = groupId  ? `&group_ids=${groupId}` : ""
    const areaParam = areaId  ? `&area_ids=${areaId}` : ""
    storeParam = regionId ? storeParam + groupParam +  areaParam : ""

    let dateTypeParam = `&date_type=${value === "specific" ? "specific_dates" : value}`
    if (!value || value === "today") {
      dateTypeParam = ""
    }

    const startDateParam = datevalue?.from ? `&start_date=${moment(datevalue.from).format("YYYY-MM-DD")}` : ""
    const endDateParam = datevalue?.to ? `&end_date=${moment(datevalue.to).format("YYYY-MM-DD")}` : ""
    const startTimeParam = timeRange?.from ? `&start_time=${moment(timeRange.from).format("hh:mm A")}` : ""
    const endTimeParam = timeRange?.to ? `&end_time=${moment(timeRange.to).format("hh:mm A")}` : ""
    const isMobileParam = activeType === "Mobile" ? "&is_mobile=true" : ""
    return regionParam + storeParam + dateTypeParam + startDateParam + endDateParam + startTimeParam + endTimeParam + isMobileParam
  }

  periodDateRange = () => {
    const {currentRange} = this.state
    const {start_date, end_date} = currentRange
    const dateFormat = "DD-MM-YYYY"
    if (!start_date && !end_date) return "";
    const startDate = moment(start_date).format(dateFormat)
    const endDate = moment(end_date).format(dateFormat)
    return `${startDate} _ ${endDate}`
  }

  timeRange = () => {
    const {currentRange} = this.state
    const {start_time, end_time} = currentRange
    if (!start_time && !end_time) return "";
    return `${start_time} to ${end_time}`
  }

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.dashboardPermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });
  }

  handleOpenExportModal = () => {
    this.setState({openExportModal: !this.state.openExportModal})
  }

  handleExportConfirm = async (method: string) => {
    if (method === "pdf") {
      this.setState({isPrinting: true} , () => {
        clearTimeout(this.exportTimer)
        this.exportTimer = setTimeout(this.handleExportToPdf, 500)
      })
    }
  }

  handleExportToPdf = async () => {
    const wrapperElement = document.getElementById("print-wrapper") as HTMLElement
    await html2pdf(wrapperElement, {
      filename: "PopularTimes-Dashboard.pdf",
      jsPDF: {
        unit: "px",
        format: [1440, 1440]
      },
      margin: 24,
      html2canvas:  { scale: 2 },
    })
    this.setState({openExportModal: false, isPrinting: false})
  }

  getPrintClass = () => {
    const hourItems = this.state.ordersByHour.filter(item => item.count || item.total_pieces)
    if (hourItems.length > 18) return "extra-long-layout"
    if (hourItems.length > 13) return "long-layout"
    return undefined
  }

  configureStorageFilter = () => {
    const applied_profession_filter = localStorage.getItem("PopularDash_filter_value");
    if (applied_profession_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_profession_filter),
          isAppliedFilter: this.checkIsFilterApplied(
            JSON.parse(applied_profession_filter),"today"
          ),
        },
        () => {
          this.fetchData()
        }
      );
    } else {
      this.fetchData()
    }
  };

  checkIsFilterApplied = (filters: IFilter[], falsyValue?: string): boolean => {
    for (const filter of filters) {
      if (filter.value) {
        if (filter.value === falsyValue) {
          return false;
        }
        return true; 
      }
    }
    return false;
  };

  // Customizable Area End
}