// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface IMeta {
  next_page: number;
  pervious_page: number;
  total_pages: number;
  total_count: number;
  current_page: number;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAppliedFilter: boolean;
  sortingData: ISortingData;
  openActionPopover: (EventTarget & HTMLButtonElement) | null;
  page: number;
  rowPerPage: number;
  meta: IMeta;
  isLoadingPermission: boolean;
  openImportExportPopover: (EventTarget & HTMLButtonElement) | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class B2bBussinessAccountListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      isAppliedFilter: false,
      openActionPopover: null,
      sortingData: {
        customer: "",
      },
      page: 0,
      rowPerPage: 10,
      meta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      isLoadingPermission: true,
      openImportExportPopover: null,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    setTimeout(() => this.setState({ isLoadingPermission: false }), 2000);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: () => {},
      onChange: () => {},
    };
  }

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  getTypeOfOrderTable = (rowIndex: number) => {
    if (rowIndex === 0) {
      return "left";
    } else if (rowIndex === 7) {
      return "right";
    } else {
      return "middle";
    }
  };

  handleCloseActionPopver = () => {
    this.setState({ openActionPopover: null });
  };

  handleOpenActionPopver = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      openActionPopover: event.currentTarget,
    });
  };

  openImportExportPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      openImportExportPopover: event.currentTarget,
    });
  };

  handleCloseImportExportPopver = () => {
    this.setState({ openImportExportPopover: null });
  };
  // Customizable Area End
}
