/** @format */

import React from "react";
// Customizable Area Start
import PreferenceListController, { Props, configJSON } from "./PreferenceListController.web"
import "./PreferenceList.css"
import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomPagination from "../../../components/src/customComponents/CustomPagination.web"
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import FilterPopover from "../../../components/src/FilterPopover";
import CustomArrowIcon from "../../../components/src/customComponents/CustomArrowIcon.web";
import {
    IconButton,
    styled,
    Box
  } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { colors } from "../../../blocks/utilities/src/Colors";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

class PreferenceList extends PreferenceListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { preferenceList, openModalId, currentPage, open, deactivated, deActivatedItem, deActivateMessage, confirmButtonText } = this.state
        const itemsPerPage = 10;
        const totalItems = preferenceList?.length;

        const openAction = Boolean(this.state.openAction);
    const popOverId = openAction ? "simple-popover" : undefined;
        return (
            <>
              <StyledBoxWrapper>
                <StyledMninDivWrapper className="preference-list-container">
                    <div className="preference-top">
                        <PrefHeading>{configJSON.preferenceTitle}</PrefHeading>
                        <div className="filtergroup">
                            <div
                                className={sortStringCondition(this.state.isFilterApplied, "filterButtonPref active-border", "filterButtonPref")}
                                data-test-id="filterIcon"
                                onClick={(event) => { this.handleFilterAnchor(event)}}
                            >
                                <FilterIcon color={this.handleReturnColorType()} />
                                {
                                    renderBaseOnConditions(
                                    this.state.isFilterApplied,
                                    <div className="filterActiveIcon"></div>,
                                    <></>
                                    )
                                }
                            </div>
                            <FilterPopover
                                onClose={() => { this.handlePopoverClose()}}
                                anchor={this.state.filterAnchor as HTMLDivElement}
                                onFilterChange={this.handleFilterChange}
                                filters={this.state.filters}
                            />
                            <div style={{ display: "none"}}>
                              <IconButton
                                data-test-id={`export-import-btn`}
                                id={"IconButton"}
                                aria-describedby={popOverId}
                                onClick={(event) => { this.iconBtnClick(event) }}
                                className="export-btn"
                              >
                                  {configJSON.exportimportText} {this.state.openAction ? <ExpandLess /> : <ExpandMore />}
                              </IconButton>
                            </div>
                            <ExportImportPopover
                              popOverId={popOverId}
                              openAction={openAction}
                              popoverOpen={this.state.openAction}
                              popoverClose={() => { this.handleCloseActionPreferenceList() }}
                              exportTemplateButtonClick={() => this.handlePreferenceExportTemplate()}
                              importCsvButtonClick={() => this.handleOpenPreferenceImportModal()}
                              exportCsvButtonClick={() => this.handlePreferenceExportCsv()}
                            />
                                {renderBaseOnConditions(this.state.permissionStatus.createPermission, <button data-test-id="add-preference" className="addButton" onClick={this.handleAddPreference}>Add Preference</button>,<></>)}
                        </div>
                    </div>
                    <div className="container-table">
                        <ul className="header-ul">
                            <li className="header-list header-color">
                                <div className="header-list-sec">
                                    <div className="section-arrow" data-test-id="sorting-header-option">
                                        <StyledTableHeader>Option</StyledTableHeader>
                                        <CustomArrowIcon
                                            up="optionUp"
                                            down="optionDown"
                                            selected={this.state.optionSorting.value}
                                            handleSelect={this.handleSortingByOption}
                                        />
                                    </div>
                                    <div className="section-arrow" data-test-id="sorting-header-service">
                                        <StyledTableHeader className="section-item">Service</StyledTableHeader>

                                        <CustomArrowIcon
                                            up="serviceUp"
                                            down="serviceDown"
                                            selected={this.state.serviceSorting.value}
                                            handleSelect={this.handleSortingByService}
                                        />

                                    </div>
                                    <div className="section-arrow active-para" data-test-id="sorting-header-icon">
                                        <StyledTableHeader>Icon</StyledTableHeader>
                                        <CustomArrowIcon
                                            up="iconUp"
                                            down="iconDown"
                                            selected={this.state.iconSorting.value}
                                            handleSelect={this.handleSortingByIcon}

                                        />
                                    </div>
                                </div>
                                <p></p>
                            </li>
                        </ul>
                        <div>
                            <ul data-test-id="preference-list" className="preference-list">

                            {renderBaseOnConditions(
                                this.state.isLoading, 
                                <>{Array(5)
                                    .fill(1)
                                    .map((_, index) => (
                                    <>
                                    <li className={`header-list border-list`}>
                                        <div className={`option-section option-sec-preference `}>
                                        {[...Array(3)].map((_, cellIndex) => (
                                            <>
                                               <StyledOptionItem >
                                                <Skeleton />
                                                </StyledOptionItem>
                                               <StyledSelectItemInner>
                                                <Skeleton />
                                                </StyledSelectItemInner>
                                               <div>
                                                   <Skeleton  />
                                               </div>
                                            </>
                                        ))}   
                                        </div>
                                        </li>
                                    </>
                            ))}</>,
                            this.displayDataHandler(itemsPerPage)?.length === 0 ? 
                            <h4 data-test-id="empty-data" className="empty-item-list">No preference to show</h4> : 
                            this.displayDataHandler(itemsPerPage)?.map((item) => (
                                <li data-test-id={`list-item_${item.id}`} key={item.id} className={`header-list border-list`}>
                                    <div className={`option-section option-sec-preference ${!item.attributes?.active && "list-opacity"}`}>
                                        <StyledOptionItem className="option-item">{item.attributes.preference_first_name}</StyledOptionItem>
                                        <StyledSelectItemInner className="section-item">{item.attributes.preference_second_name}</StyledSelectItemInner>
                                        <div className="image-container">
                                            <StylesImg
                                                src={item.attributes.gallery_icon === null ? this.state.defaultIcon : item.attributes.gallery_icon.image} alt="icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="position-relative">
                                        <div
                                            data-test-id={`icon-more_${item.id}`}
                                            onClick={() => this.handleClick(item.id)}
                                            className="icon-moreVert">
                                            <MoreVertIcon />
                                        </div>
                                        {openModalId === item.id && 
                                            renderBaseOnConditions(
                                                this.state.permissionStatus.viewPermission || this.state.permissionStatus.editPermission || this.state.permissionStatus.activatePermission || this.state.permissionStatus.deactivatePermission,
                                                (
                                            <div className="postion-container" data-test-id="positionContainer">
                                                {renderBaseOnConditions(this.state.permissionStatus.viewPermission, <button data-test-id={`viewButton_${item.id}`} onClick={() => this.handleView(item)} >View</button>,<></>)}
                                                {renderBaseOnConditions(this.state.permissionStatus.editPermission,<button data-test-id={`editButton_${item.id}`} onClick={() => this.handleEdit(item)}>Edit</button>,<></>)}
                                                
                                                {
                                renderBaseOnConditions(
                                    this.state.permissionStatus.deactivatePermission && item?.attributes?.active,
                                    <button data-test-id={`deleteButton_${item.id}`} onClick={() => this.handleOpenModal(item)}>
                                            Deactivate
                                        </button>,
                                    <>{!this.state.permissionStatus.viewPermission &&
                                    !this.state.permissionStatus.editPermission &&
                                    this.state.permissionStatus.activatePermission &&
                                    item?.attributes?.active
                                    && !this.state.permissionStatus.deactivatePermission && <PopoverContainer1>
                                    <div className="noPermissions">
                                    No Permissions
                                    </div>
                                </PopoverContainer1>}</>
                                )
                                }
                                {
                                renderBaseOnConditions(
                                    this.state.permissionStatus.activatePermission as boolean &&  !item?.attributes?.active,
                                    <button data-test-id={`deleteButton_${item.id}`} onClick={() => this.handleOpenModal(item)}>
                                        Activate
                                    </button>,
                                    <>{!this.state.permissionStatus.viewPermission &&
                                    !this.state.permissionStatus.editPermission &&
                                    !this.state.permissionStatus.activatePermission &&
                                    !item?.attributes?.active
                                    && this.state.permissionStatus.deactivatePermission && <PopoverContainer1>
                                    <div className="noPermissions">
                                    No Permissions
                                    </div>
                                </PopoverContainer1>}
                                </>
                                )
                                }
                        
                                            </div>
                                                ),
                                                <div className="postion-container" data-test-id="positionContainer">
                                                    <button>No Permissions</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </li>
                            ))
                            )}

                               
                            </ul>
                        </div>
                    </div>
                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={this.totalPageHandler(totalItems, itemsPerPage)}
                        totalItems={totalItems}
                        goToNextPage={this.goToNextPage}
                        goToPreviousPage={this.goToPreviousPage}
                        itemsPerPage={itemsPerPage}
                    />
                    <CustomSnackbar
                        open={this.state.errorSnackbarOpen}
                        onClose={this.handleSnackbarClose}
                        errorMessage={this.state.errorMessage}
                        severity={this.state.snakcbarSeverity}
                    />
                </StyledMninDivWrapper>
                <ConfirmationModal
                    open={open}
                    handleClose={this.handleCloseConfirmModal}
                    handleConfirm={this.handleDelete}
                    title="Confirm Deactivate"
                    message={deActivatedItem}
                    confirmText="Deactivate"
                    dynamic={true}
                />

                {deactivated && (
                    <ConfirmationModal
                        open={deactivated}
                        handleClose={this.handleCloseConfirmModal}
                        handleConfirm={this.handleGoBack}
                        title={deActivateMessage}
                        message={deActivatedItem}
                        confirmText={confirmButtonText}
                        displayItem={true}
                    />
                )}
              </StyledBoxWrapper>
            </>
        );
    }
    // Customizable Area End
}
export default PreferenceList;
// Customizable Area Start
const StyledBoxWrapper = styled(Box)({ 
  "& .export-btn": {
    width: 'auto !important',
    margin: '0 10px'
  },

  "& .addButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px !important",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: '184px !important',
    borderRadius: "8px",
    textTransform: "unset",
    marginLeft: "20px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important",
      width: "160px !important",
      height: "44px !important",
      padding: "10px !important"
    },
  },
  "& .filterButtonPref": {
    position: "relative",  
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color:  colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor:colors().lightborder,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
    "&.active-border": {
        borderColor: colors().cyancobaltblue,
    },
    "& .filterActiveIcon": {
        position: "absolute",
        background: colors().cyancobaltblue,
        top: "-5px",
        right: "-5px",
        minWidth: "15px",
        minHeight: "15px",
        borderRadius: "50%"
      },
  },
});

const StyledMninDivWrapper = styled('div')({ 
    padding:0,
    marginTop:0,
    marginBottom: 0,
    "& .image-container": {
        "@media only screen and (max-width: 1024px)": {
            marginLeft: "20px"
        }
    } 
});

const StyledOptionItem = styled('p')({
	flexBasis: "20%",
	fontSize: "16px",
	fontWeight: 600,
    marginLeft: "5px",
	color: colors().viewTextColor,
	"@media only screen and (max-width: 1024px)": {
		fontSize: "12px",
		fontWeight: "400 !important",
		color: colors().viewTextColor,
	},
});

const StyledSelectItemInner = styled('p')({
	flexBasis: "14% !important",
	fontSize: "16px",
	fontWeight: 600,
	color: colors().viewTextColor,
	"@media only screen and (max-width: 1024px)": {
		fontSize: "12px",
		fontWeight: 400,
		color: colors().viewTextColor,
	},
});

const StylesImg = styled('img')({
    "@media only screen and (max-width: 1024px)": {
		width: "20px !important",
		height: "20px !important",
	},
})

export const PrefHeading = styled("p")({
    lineHeight: "30px",
    fontWeight: 600,
    color: colors().black,
    fontSize: "24px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: 600,
      color: colors().black,
    },
    "@media only screen and (max-width: 600px)": {
        marginBottom: "20px",
      },
  });

const StyledTableHeader = styled('p')({
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "18px",
    color: colors().background,
    "@media only screen and (max-width: 1024px)": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "13px",
        color: colors().background,
	},
})

const PopoverContainer1 = styled("div")({
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    "& .noPermissions": {
      padding: "0 8px",
    }
  })


// Customizable Area End
